import {
  Box,
  Fab,
  IconButton,
  Grid,
  Typography,
  Paper,
  InputBase,
  Card,
  CardContent,
} from "@mui/material";
import { Delete, Clear } from "@mui/icons-material";
import Table from "./Table";
import { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { getDoc, getDocumentTypes } from "apis/documents";
import DocumentTypeDataDialog from "./DocumentTypeDataDialog";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/config";
import { getDownloadURL, getMetadata, getStorage, ref } from "firebase/storage";

import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import expressionParser from "docxtemplater/expressions";
import { Context } from "context/Wrapper";

let searchTimeout = null;

function loadFile(url, callback) {
  return PizZipUtils.getBinaryContent(url, callback);
}

const DocumentsTypes = ({ projectId, getProject }) => {
  const [typeDialogOpen, setTypeDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [filteredDocumentTypes, setFilteredDocumentTypes] = useState([]);

  const context = useContext(Context);
  const user = context?.state?.userProfile || {};


  useEffect(() => {
    getDocumentTypes(setDocumentTypes);
  }, []);

  useEffect(() => {
    if (searchTimeout !== null) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      setFilteredDocumentTypes(
        documentTypes?.filter((item) =>
          item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()),
        ),
        // ?.map((item) => ({
        //     ...item,
        //     name: item?.name?.replace(
        //       searchValue,
        //       (match) =>
        //         `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //     ),
        //     dateSent: item?.dateSent?.replace(
        //       searchValue,
        //       (match) =>
        //         `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //     ),
        //     recipient: item?.recipient?.replace(
        //       searchValue,
        //       (match) =>
        //         `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //     ),
        //     dateReceived: item?.dateReceived?.replace(
        //       searchValue,
        //       (match) =>
        //         `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //     ),
        //     comments: item?.comments?.replace(
        //       searchValue,
        //       (match) =>
        //         `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //     ),
        //   }))
      );
    }, 800);
  }, [documentTypes, searchValue]);

  const handleDocumentData = async (documents) => {
    const projectRef = doc(db, "documentTypes", projectId);
    await updateDoc(projectRef, {
      documents,
    });
    getProject();
  };

  const handleDownload = async (path) => {
    const storage = getStorage();
    const storageRef = ref(storage, path);
    const link = await getDownloadURL(storageRef);
    // await generateDocument(link);
    const linkInfo = await getMetadata(storageRef);
    const aElement = document.createElement("a");

    aElement.setAttribute(
      "download",
      `${linkInfo?.name}.${linkInfo.contentType?.split("/")?.pop()}`,
    );

    aElement.href = link;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    aElement.remove();
  };

  return (
    <Card
      sx={{
        mt: 3,
        width: { xs: "100%", md: "50%" },
        maxHeight: "500px",
        overflow: "auto",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
            <Typography align="left" variant="h4">
              Documents Types
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Paper
              component="form"
              onSubmit={(e) => e.preventDefault()}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginLeft: "auto",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                value={searchValue}
                inputProps={{ "aria-label": "search" }}
                onInput={(e) => setSearchValue(e.target.value)}
              />
              {searchValue.length > 0 ? (
                <IconButton
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => setSearchValue("")}
                >
                  <Clear />
                </IconButton>
              ) : (
                <IconButton sx={{ p: "10px" }} aria-label="clear">
                  <SearchIcon />
                </IconButton>
              )}
            </Paper>
          </Grid>
        </Grid>

        <Table
          documentTypes={filteredDocumentTypes}
          handleDocumentData={handleDocumentData}
          setAddDialogOpen={setTypeDialogOpen}
          reloadData={() => getDocumentTypes(setDocumentTypes)}
          handleDownload={handleDownload}
        />

  {user.subscription!=='trial' &&(
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            "& > :not(style)": { m: 1 },
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={() => setTypeDialogOpen(true)}
          >
            <AddIcon sx={{ mr: 1 }} />
            New Type
          </Fab>
        </Box>
         )}
        <DocumentTypeDataDialog
          open={typeDialogOpen}
          handleDocumentData={handleDocumentData}
          reloadData={() => getDocumentTypes(setDocumentTypes)}
          setDialogOpen={setTypeDialogOpen}
          documentTypes={documentTypes}
          handleDownload={handleDownload}
        />
      </CardContent>
    </Card>
  );
};

export default DocumentsTypes;
