import { Save, Close } from "@mui/icons-material";
import { Alert, Box, Button, Fab, Grid, MenuItem, Select, Snackbar, TextField, Typography, CircularProgress } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SaveCloseDialog } from 'components/SaveCloseDialog';
import InfiniteScrollSelect from "components/shared/InfiniteScrollSelect";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react'
import { db } from "config/config";
import { doc, getDoc, setDoc } from "firebase/firestore";
import CreateInvoiceTable from "components/invoiceTable";
import { useNavigate } from "react-router-dom";
import { CreateUniqueDocument } from "components/shared/createUniqueDocument ";
import { textTransform } from "@mui/system";
import { Context } from "context/Wrapper";
import { useFetchCollectionDocs } from "apis/dataSnapshots";
import { debounce } from 'lodash';

const useLastInvoiceNumber = (format, groupId) => {
    const [loading, setLoading] = useState(true);
    const [lastNumber, setLastNumber] = useState(null);

    useEffect(() => {
        if (!format) return;

        const base = format.replace(/#/g, "");
        const fetchLastNumber = async () => {
            try {
                // Only fetch the last invoice number matching the format
                const query = await db.collection("invoices")
                    .where("groupId", "==", groupId)
                    .where("invoiceNumber", ">=", base)
                    .where("invoiceNumber", "<=", base + "\uf8ff")
                    .orderBy("invoiceNumber", "desc")
                    .limit(1)
                    .get();

                if (!query.empty) {
                    const lastInvoice = query.docs[0].data();
                    const numberPart = lastInvoice.invoiceNumber.replace(base, "");
                    setLastNumber(parseInt(numberPart));
                } else {
                    setLastNumber(0);
                }
            } catch (error) {
                console.error("Error fetching last invoice number:", error);
                setLastNumber(0);
            } finally {
                setLoading(false);
            }
        };

        fetchLastNumber();
    }, [format, groupId]);

    return { lastNumber, loading };
};

export default function InvoiceNew({ invoiceData, invoiceId, handleChangeTab }) {

    const context = useContext(Context);

    const { groupId } = context?.state?.userProfile || {};
    const userProfile = context?.state?.userProfile || {};


    // Extend dayjs with the utc plugin
    dayjs.extend(utc);

    const navigate = useNavigate();
    const currentDate = dayjs().utc(); // Get the current date in the same format as dueDate
    const [invoiceDetails, setinvoiceDetails] = useState(invoiceData ? invoiceData : {
        billingDate: currentDate.toDate().toString(),
        invoiceFor: "",
        invoiceNumber: "",
        invoiceJob: "",
        dueDate: currentDate.toDate().toString(),
        invoiceRemarks: "",
        adjustments: 0
    });

    // changes
    const [itemDate, setItemDate] = useState(currentDate.toDate().toString());
    const [itemType, setItemType] = useState('Hourly');
    const [itemHours, setItemHours] = useState(0);
    const [itemHourPrice, setItemHourPrice] = useState(0);
    const [itemAmount, setItemAmount] = useState(0);
    const [itemDescription, setItemDescription] = useState('');
    const [itemList, setItemList] = useState(invoiceData ? invoiceData.currCharges : []);

    // payments
    const [itemPaymentsDate, setItemPaymentsDate] = useState(currentDate.toDate().toString());
    const [itemPaymentsType, setItemPaymentsType] = useState('');
    const [customPaymentType, setCustomPaymentType] = useState('');
    // const [itemPaymentsAdjustments, setItemPaymentsAdjustments] = useState(0);
    const [itemPaymentsAmount, setItemPaymentsAmount] = useState(0);
    const [itemPaymentsList, setItemPaymentsList] = useState(invoiceData ? invoiceData.payments : []);

    // Validation
    const [isValid, setIsValid] = useState(true);
    const [isChargesValid, setIsChargesValid] = useState(false);

    // check duplication
    const [duplicateOpen, setduplicateOpen] = React.useState(false);
    const [SubmitLoader, setSubmitLoader] = React.useState(false);



    const projects = useFetchCollectionDocs({
        collectionName: "projects",
        conditions: [true],
    });

    const contacts = useFetchCollectionDocs({
        collectionName: "contacts",
        conditions: [true],
    });

    // Memoize expensive calculations
    const totalCharges = useMemo(() =>
        itemList.reduce((total, item) => Number(item.itemAmount) + total, 0),
        [itemList]
    );

    const totalPayments = useMemo(() =>
        itemPaymentsList.reduce((total, item) => Number(item.itemPaymentsAmount) + total, 0),
        [itemPaymentsList]
    );

    // Memoize handlers
    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setinvoiceDetails(prev => ({
            ...prev,
            [name]: value
        }));
    }, []);

    const handlePaymentTypeChange = (e) => {
        const value = e.target.value;
        setItemPaymentsType(value);
        if (value !== 'Other') {
            setCustomPaymentType(''); // Clear custom value if not 'Other'
        }
    };

    const handleduplicateOpen = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setduplicateOpen(false);
    };


    const addItem = (e, type = "changes") => {
        e.preventDefault();

        if (type == "changes") {
            if (itemType !== "") {
                // If both itemHours and itemHourPrice are provided, calculate the itemAmount
                const calculatedAmount = itemHours && itemHourPrice
                    ? Number(itemHourPrice * itemHours)
                    : itemAmount;

                setItemList([
                    ...itemList,
                    {
                        itemId: Date.now().toString(36) + Math.random().toString(36).substring(2),
                        itemDate,
                        itemType,
                        itemHours,
                        itemHourPrice,
                        itemAmount: calculatedAmount,
                        itemDescription
                    },
                ]);
            }

            setItemDate(currentDate.toDate().toString());
            setItemType('Hourly');
            setItemHours(0);
            setItemHourPrice(0);
            setItemAmount(0);
            setItemDescription('');
        } else {
            if (itemPaymentsType !== "" && itemPaymentsAmount > 0) {
                setItemPaymentsList([
                    ...itemPaymentsList,
                    {
                        itemPaymentsId: Date.now().toString(36) + Math.random().toString(36).substring(2),
                        itemPaymentsDate,
                        itemPaymentsType: itemPaymentsType === "Other" ? customPaymentType : itemPaymentsType,
                        itemPaymentsAmount,
                    },
                ]);
            }

            setItemPaymentsDate(currentDate.toDate().toString());
            setItemPaymentsType('');
            setItemPaymentsAmount(0);
        }

    };

    const handleDelete = useCallback((id, type) => {
        if (type === "changes") {
            setItemList(prev => prev.filter(item => item.itemId !== id));
        } else {
            setItemPaymentsList(prev => prev.filter(item => item.itemPaymentsId !== id));
        }
    }, []);

    const handleDateChange = (date) => {
        // date can be a dayjs object, format it if necessary
        setinvoiceDetails((prevDetails) => ({
            ...prevDetails,
            dueDate: date ? date.utc().$d.toString() : "", // Store date in desired format
        }));
    };

    // Debounce the submit function
    const debouncedSubmit = useMemo(
        () => debounce(() => {
            if (!isValid || SubmitLoader) return;

            const invoiceCollection = {
                ...invoiceDetails,
                userId: invoiceData ? invoiceData.userId : userProfile.uid,
                groupId,
                phone: invoiceData ? invoiceData?.phone : userProfile?.phone ?? null,
                name: invoiceData ? invoiceData.name : userProfile.displayName,
                email: invoiceData ? invoiceData.email : userProfile.email,
                currCharges: itemList,
                payments: itemPaymentsList,
                totalCharges,
                totalPayments,
                balanceDue: totalCharges - (totalPayments + Number(invoiceDetails.adjustments)),
                customerName: contacts?.find((contact) => contact.id === invoiceDetails.invoiceFor)?.fullName || invoiceDetails.invoiceFor,
                jobName: projects?.find((project) => project.id === invoiceDetails.invoiceJob)?.customerReference || invoiceDetails.invoiceJob
            };

            if (invoiceId) {
                setSubmitLoader(true)
                // Normalize the full name for comparison
                const normalizedinvoiceNumber = invoiceCollection.invoiceNumber.toString().trim();
                const thisInvoice = doc(db, "invoices", invoiceId);

                db.collection("invoices").where("groupId", "==", groupId).get().then((querySnapshot) => {
                    // Check if there's any other document with the same name but different ID
                    const isDuplicate = querySnapshot.docs.some(doc => {
                        const docData = doc.data();
                        const docinvoiceNumber = docData.invoiceNumber ? docData.invoiceNumber.toString().trim().toLowerCase() : '';
                        return doc.id !== invoiceId && docinvoiceNumber === normalizedinvoiceNumber.toLowerCase();
                    });

                    if (!isDuplicate) {
                        // If no duplicates, proceed to update the document
                        setDoc(thisInvoice, {
                            ...invoiceCollection,
                            invoiceNumber: normalizedinvoiceNumber, // Store original case-sensitive version
                        }, { merge: true })
                            .then(() => {
                                setSubmitLoader(false);
                                handleChangeTab("", 0);
                            })
                            .catch((error) => {
                                console.error("Error updating document: ", error);
                                setSubmitLoader(false)
                            })
                    } else {
                        // If a matching document is found, display an error
                        setduplicateOpen(true);
                        setSubmitLoader(false);
                    }
                })
                    .catch((error) => {
                        console.error("Error checking for unique: ", error);
                        setSubmitLoader(false)
                    });

            } else {
                CreateUniqueDocument({
                    navigate: navigate,
                    db: db,
                    collectionName: "invoices",
                    data: invoiceCollection,
                    uniqueFieldName: "invoiceNumber",
                    failureCallback: () => {
                        console.error("Error adding document.");
                        setSubmitLoader(false)
                    },
                    duplicateCallback: () => {
                        setduplicateOpen(true); // Handle duplicate case
                        setSubmitLoader(false)
                    },
                    loadingCallback: () => {
                        setSubmitLoader(true)
                    }
                });
            }
        }, 300),
        [invoiceDetails, itemList, itemPaymentsList, isValid, SubmitLoader, totalCharges, totalPayments]
    );

    function zeroPad(num, count) {
        var numZeropad = num + "";
        while (numZeropad.length < count) {
            numZeropad = "0" + numZeropad;
        }
        return numZeropad;
    }




    useEffect(() => {
        const hasRequiredFields =
            invoiceDetails.invoiceFor &&
            invoiceDetails.invoiceJob &&
            invoiceDetails.dueDate &&
            itemList[0] &&
            invoiceDetails.invoiceNumber.length > 2;

        setIsValid(hasRequiredFields);
    }, [
        invoiceDetails.invoiceFor,
        invoiceDetails.invoiceJob,
        invoiceDetails.dueDate,
        invoiceDetails.invoiceNumber,
        itemList
    ]);

    const { lastNumber, loading: numberLoading } = useLastInvoiceNumber(
        userProfile.invoiceNumberFormat,
        groupId
    );

    const generateNextInvoiceNumber = useCallback(() => {
        if (!userProfile.invoiceNumberFormat || numberLoading) return;

        const format = userProfile.invoiceNumberFormat;
        const base = format.replace(/#/g, "");
        const digits = format.split("#").length - 1;
        const nextNumber = (lastNumber || 0) + 1;

        const paddedNumber = nextNumber.toString().padStart(digits, "0");
        const nextInvoiceNumber = base + paddedNumber;

        setinvoiceDetails(prev => ({
            ...prev,
            invoiceNumber: nextInvoiceNumber
        }));
    }, [userProfile.invoiceNumberFormat, lastNumber, numberLoading]);

    // Replace the existing autoNumberInvoice effect with this
    useEffect(() => {
        if (userProfile.invoiceAutonumber && !invoiceId && !numberLoading) {
            generateNextInvoiceNumber();
        }
    }, [userProfile.invoiceAutonumber, invoiceId, numberLoading, generateNextInvoiceNumber]);

    // Add loading state to your UI
    const isLoading = numberLoading && userProfile.invoiceAutonumber && !invoiceId;

    useEffect(() => {
        if (itemType === "Fixed") {
            setIsChargesValid(itemAmount >= 1);
        } else {
            setIsChargesValid(itemHours >= 1 && itemHourPrice >= 1);
        }
    }, [itemHours, itemHourPrice, itemType, itemAmount]);

    return (
        <>

            {
                invoiceData ? <Typography
                    sx={{ textTransform: "uppercase", marginBottom: "1rem" }}
                    align="left"
                    variant="h4"
                >
                    Edit Invoice
                </Typography> : <Typography
                    sx={{ textTransform: "uppercase", marginBottom: "1rem" }}
                    align="left"
                    variant="h4"
                >
                    New Invoice
                </Typography>
            }


            <form name="invoiceForm" id="invoiceForm" style={{ paddingBottom: "50px" }}>

                <Grid container spacing={4}>

                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            label="Invoice Number"
                            type="text"
                            required
                            name="invoiceNumber"
                            variant="outlined"
                            value={invoiceDetails.invoiceNumber}
                            onChange={handleChange}
                            fullWidth
                            InputProps={{
                                endAdornment: isLoading && (
                                    <CircularProgress size={20} />
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <InfiniteScrollSelect
                            collectionName="contacts"
                            onChange={handleChange}
                            value={invoiceDetails.invoiceFor}
                            label="fullName"
                            name="invoiceFor"
                            inputLabel="Invoice For: "
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <InfiniteScrollSelect
                            collectionName="projects"
                            label="customerReference"
                            onChange={handleChange}
                            value={invoiceDetails.invoiceJob}
                            name="invoiceJob"
                            inputLabel="For Job: "
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Payment Due Date"
                                    name="dueDate"
                                    value={invoiceDetails.dueDate ? dayjs(invoiceDetails.dueDate) : null} // Initialize the DatePicker value with the stored date
                                    onChange={handleDateChange}
                                    sx={{ width: '100%' }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                        },
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="w-100" display="flex" justifyContent="space-between" flexDirection="column">
                            <h3 style={{ fontWeight: 'bold' }}>Current Charges:</h3>

                            <Box display="flex" gap={3}>
                                {/* Date */}
                                <Box display="flex" flexDirection="column" width="25%">
                                    <label htmlFor="itemDate" className="text-sm">Date</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer sx={{ paddingTop: 0 }} components={['DatePicker']}>
                                            <DatePicker
                                                name="itemDate"
                                                value={itemDate ? dayjs(itemDate) : null}
                                                onChange={(newValue) => {
                                                    // Convert the selected date to native Date object and store as a string
                                                    setItemDate(newValue ? dayjs(newValue).toDate().toString() : '');
                                                }}
                                                sx={{ width: '100%', padding: "0px" }}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                    },
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>


                                {/* Type */}
                                <Box display="flex" flexDirection="column" width="25%">
                                    <label htmlFor="itemType" className="text-sm">Type</label>
                                    <Select
                                        id="itemType"
                                        value={itemType}
                                        onChange={(e) => {
                                            setItemType(e.target.value)
                                            setItemHours(0);
                                            setItemHourPrice(0);
                                        }}
                                        variant="outlined"
                                        size="small"
                                        displayEmpty
                                        sx={{
                                            height: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                height: '100%',
                                            },
                                        }}
                                    >
                                        <MenuItem value="Hourly">Hourly</MenuItem>
                                        <MenuItem value="Daily">Daily</MenuItem>
                                        <MenuItem value="Fixed">Fixed</MenuItem>
                                    </Select>
                                </Box>

                                {/* Hours */}
                                <Box display="flex" flexDirection="column" width="25%">
                                    <label htmlFor="itemQuantity" className="text-sm">Quantity</label>
                                    <TextField
                                        type="number"
                                        id="itemQuantity"
                                        placeholder="Quantity"
                                        variant="outlined"
                                        size="small"
                                        disabled={itemType === 'Fixed' ? true : false}
                                        value={itemHours}
                                        onChange={(e) => setItemHours(e.target.value)}
                                        sx={{
                                            height: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                height: '100%',
                                            },
                                            '& input': {
                                                height: '100%',
                                            },
                                        }} />
                                </Box>

                                {/* Hourly Rate */}
                                <Box display="flex" flexDirection="column" width="25%">
                                    <label htmlFor="itemHourPrice" className="text-sm">Price</label>
                                    <TextField
                                        type="number"
                                        id="itemHourPrice"
                                        placeholder="Price"
                                        variant="outlined"
                                        size="small"
                                        disabled={itemType === 'Fixed' ? true : false}
                                        value={itemHourPrice}
                                        onChange={(e) => setItemHourPrice(e.target.value)}
                                        sx={{
                                            height: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                height: '100%',
                                            },
                                            '& input': {
                                                height: '100%',
                                            },
                                        }} />
                                </Box>

                                {/* Total Amount */}
                                <Box display="flex" flexDirection="column" width="25%">
                                    <label htmlFor="totalAmount" className="text-sm">Amount</label>
                                    <TextField
                                        type="number"
                                        id="totalAmount"
                                        placeholder="Amount"
                                        variant="outlined"
                                        size="small"
                                        value={itemType === 'Fixed' ? itemAmount : Number(itemHourPrice * itemHours)}
                                        disabled={itemType !== 'Fixed' ? true : false} // Disable if both itemHours and itemHourPrice are provided
                                        onChange={(e) => itemType === 'Fixed' ? setItemAmount(e.target.value) : null} // Allow manual input if either is missing
                                        sx={{
                                            height: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                height: '100%',
                                            },
                                            '& input': {
                                                height: '100%',
                                            },
                                        }} />
                                </Box>

                                {/* Item Description */}
                                <Box display="flex" flexDirection="column" width="100%">
                                    <label htmlFor="itemDescription" className="text-sm">Description</label>
                                    <TextField
                                        type="text"
                                        id="itemDescription"
                                        placeholder="Item Description"
                                        variant="outlined"
                                        size="small"
                                        value={itemDescription}
                                        onChange={(e) => setItemDescription(e.target.value)}
                                        sx={{
                                            height: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                height: '100%',
                                            },
                                            '& input': {
                                                height: '100%',
                                            },
                                        }} />
                                </Box>

                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: 'fit-content', padding: '10px', borderRadius: '8px', marginTop: '10px' }}
                                disabled={itemType == "" || !isChargesValid}
                                onClick={(e) => addItem(e, "changes")}
                            >
                                Add Charges Item
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        {itemList[0] && <CreateInvoiceTable itemList={itemList} handleDelete={handleDelete} tableType="changes" />}
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="w-100" display="flex" justifyContent="space-between" flexDirection="column">
                            <h3 style={{ fontWeight: 'bold' }}>Payments:</h3>

                            <Box display="flex" gap={3}>

                                {/* Date */}
                                <Box display="flex" flexDirection="column" width="25%">
                                    <label htmlFor="itemPaymentsDate" className="text-sm">Date</label>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            sx={{ paddingTop: 0 }}
                                            components={['DatePicker']}>
                                            <DatePicker
                                                name="itemPaymentsDate"
                                                value={itemPaymentsDate ? dayjs(itemPaymentsDate) : null}
                                                onChange={(newValue) => {

                                                    setItemPaymentsDate(newValue ? dayjs(newValue).toDate().toString() : '');
                                                }} sx={{ width: '100%', padding: "0px" }}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                    },
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                </Box>


                                {/* Type */}
                                <Box display="flex" flexDirection="column" width="25%">
                                    <label htmlFor="itemPaymentsType" className="text-sm">Type</label>
                                    <Select
                                        id="itemPaymentsType"
                                        value={itemPaymentsType}
                                        onChange={(e) => setItemPaymentsType(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        displayEmpty
                                        sx={{
                                            height: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                height: '100%',
                                            },
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Type
                                        </MenuItem>
                                        <MenuItem value="Check">Check</MenuItem>
                                        <MenuItem value="Online">Online</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>

                                    {/* Show TextField when "Other" is selected */}
                                    {itemPaymentsType === 'Other' && (
                                        <TextField
                                            id="customPaymentType"
                                            placeholder="Enter your payment type"
                                            variant="outlined"
                                            size="small"
                                            value={customPaymentType}
                                            onChange={(e) => setCustomPaymentType(e.target.value)}
                                            sx={{
                                                marginTop: '8px', // Adjust as needed
                                                height: '100%',
                                                '& .MuiOutlinedInput-root': {
                                                    height: '100%',
                                                },
                                                '& input': {
                                                    height: '100%',
                                                },
                                            }}
                                        />
                                    )}
                                </Box>

                                {/* Total Amount */}
                                <Box display="flex" flexDirection="column" width="25%">
                                    <label htmlFor="totalPaymentsAmount" className="text-sm">Total Payments Amount</label>
                                    <TextField
                                        type="number"
                                        id="totalPaymentsAmount"
                                        placeholder="Total Payments Amount"
                                        variant="outlined"
                                        size="small"
                                        value={itemPaymentsAmount}
                                        onChange={(e) => setItemPaymentsAmount(e.target.value)}
                                        sx={{
                                            height: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                height: '100%',
                                            },
                                            '& input': {
                                                height: '100%',
                                            },
                                        }} />
                                </Box>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: 'fit-content', padding: '10px', borderRadius: '8px', marginTop: '10px' }}
                                disabled={itemPaymentsAmount < 1 || itemPaymentsType == ""}
                                onClick={(e) => addItem(e, "payments")}
                            >
                                Add Payments Item
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        {itemPaymentsList[0] && <CreateInvoiceTable itemList={itemPaymentsList} handleDelete={handleDelete} tableType="payments" />}
                    </Grid>

                    {/* Ajustments */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className="w-100" display="flex" justifyContent="space-between" flexDirection="column">
                            <h3 style={{ fontWeight: 'bold' }}>Adjustments:</h3>
                            <small style={{ marginBottom: '5px', textTransform: 'capitalize' }}>If you would like to make any adjustments to the balance due, add it here.</small>
                            <TextField
                                type="number"
                                id="adjustments"
                                name="adjustments"
                                label="Adjustments"
                                variant="outlined"
                                size="small"
                                value={invoiceDetails.adjustments}
                                onChange={handleChange}
                                sx={{
                                    height: '100%',
                                    '& .MuiOutlinedInput-root': {
                                        height: '100%',
                                    },
                                    '& input': {
                                        height: '100%',
                                    },
                                }} />
                        </Box>

                    </Grid>



                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            id="remarks"
                            label="Remarks"
                            onChange={handleChange}
                            value={invoiceDetails.invoiceRemarks}
                            name="invoiceRemarks"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows="6"
                        />
                    </Grid>

                </Grid>
            </form>
            <SaveCloseDialog
            />

            <Box
                sx={{
                    position: "fixed",
                    bottom: "1rem",
                    right: "1rem",
                    "& > :not(style)": { m: 1 },
                }}
            >

                <Fab
                    color="primary"
                    aria-label="add"
                    variant="extended"
                    onClick={debouncedSubmit}
                    disabled={!isValid || SubmitLoader}
                    form="invoiceForm"
                >
                    <Save sx={{ mr: 1 }} />
                    Save
                </Fab>
                <Fab
                    color="secondary"
                    aria-label="add"
                    variant="extended"
                    onClick={() => navigate("/invoices")}
                >
                    <Close sx={{ mr: 1 }} />
                    Close
                </Fab>
            </Box>
            <Snackbar autoHideDuration={2000}>
                <Alert severity="success" sx={{ width: "100%" }}>
                    Invoice successfully saved!
                </Alert>
            </Snackbar>
            <Snackbar
                autoHideDuration={2000}
            >
                <Alert
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Something went wrong!
                </Alert>
            </Snackbar>

            <Snackbar
                open={duplicateOpen}
                autoHideDuration={2000}
                onClose={handleduplicateOpen}
            >
                <Alert
                    onClose={handleduplicateOpen}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Invoice Number Already Exists!
                </Alert>
            </Snackbar>
        </>
    );

}
