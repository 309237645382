import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { auth, db } from "config/config"; // Make sure to import auth
import "../styles/AgreeToTerms.css"

const AgreeToTerms = () => {
  const [hasAgreed, setHasAgreed] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true); // State for overlay
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserProfile = async () => {
      const user = auth.currentUser; // Get the current user
      if (user) {
        const userId = user.uid; // Get the user ID
        try {
          const userDoc = await db.collection("profiles").doc(userId).get();
          if (userDoc.exists) {
            const userProfile = userDoc.data();

            if (userProfile.agreedToTerms === undefined) {
              await db.collection("profiles").doc(userId).update({
                agreedToTerms: false,
              });
            }

            if (userProfile.agreedToTerms) {
              navigate("/"); // Navigate if already agreed
            } else {
              setShowOverlay(true); // Show overlay if not agreed
            }
          } else {
            console.log("User profile not found");
          }
        } catch (error) {
          console.error("Error loading user profile:", error);
        }
      } else {
        console.log("No user is signed in");
        // Optionally, navigate to a login page or show an error
      }
    };

    loadUserProfile();
  }, [db, navigate]);

  const handleAgree = async () => {
    const user = auth.currentUser; // Get the current user again
    if (user && hasAgreed) {
      const userId = user.uid; // Get the user ID
      try {
        await db.collection("profiles").doc(userId).update({
          agreedToTerms: true,
        });
        setShowOverlay(false); // Hide overlay on agreement
        navigate("/"); // Navigate after agreeing
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    } else {
      alert("You must agree to the terms to proceed.");
    }
  };

  return (
    <div>
      {showOverlay && (
        <div className="agreeToTermsOverlay">
          <div className="agreeToTermsModal">
            <Typography variant="h4" gutterBottom>
              Agree to Terms
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please read and agree to the terms and conditions and privacy policy before proceeding.
            </Typography>
            <Checkbox
              checked={hasAgreed}
              onChange={(e) => setHasAgreed(e.target.checked)}
            />
            I agree to the{" "}
            <a href="https://xpdtr.com/index.php/terms-and-conditions" target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="https://xpdtr.com/index.php/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            <br />
            <button onClick={handleAgree} className="agreeToTermsBtn" style={{ marginTop: "20px" }}>
              Proceed
            </button>
          </div>
        </div>
      )}
     
    </div>
  );
};




export default AgreeToTerms;
