

import React, { useContext } from "react";

// components
import { styled, useTheme } from "@mui/material/styles";

import {
  Fade,
  Button,
  Grid,
  Typography,
  Paper,
  TextField,
  Snackbar,
  Alert,
  Switch,
} from "@mui/material";

import { doc, getDoc, setDoc } from "firebase/firestore";


import {
  Business as BuildingIcon,
  FireExtinguisher as FireExtinguisherIcon,
} from "@mui/icons-material";
import DocumentsTypes from "components/DocumentsTypes";
import { Context } from "context/Wrapper";
import { db } from "config/config";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import Swal from "sweetalert2"; // Import SweetAlert


const ListItem = styled(Paper)(({ theme }) => {
  return {
    width: "100%",
    padding: theme.spacing(1),
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: 0,
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});

export const Profile = () => {
  const context = useContext(Context);

  const user = context?.state?.userProfile || {};

  const [groupName, setGroupName] = React.useState(false);
const [groupAddress,setGroupAddress]=React.useState(false);
const [groupTelephone,setGroupTelephone]=React.useState(false);
const [groupFax,setGroupFax]=React.useState(false);


  const storage = getStorage();
  const [newLogoFile, setNewLogoFile] = React.useState(null);
  const [logoUrl, setLogoUrl] = React.useState("");


  const checkIfGroupExists = async (newGroupId) => {
    try {
      const docSnapshot = await db.collection("groups").doc(newGroupId).get();

      return docSnapshot.exists;
    } catch (error) {
      console.error("Error checking for group existence:", error);
      throw error;
    }
  };


  const getGroupInfo = async (groupId) => {
    try {
      const docSnapshot = await db.collection("groups").doc(groupId).get();

      if (docSnapshot.exists) {
        setGroupName(docSnapshot.data().groupName);
       setGroupAddress(docSnapshot.data().groupAddress)
       setGroupTelephone(docSnapshot.data().groupTelephone)
       setGroupFax(docSnapshot.data().groupFax)
      } else {
        console.log(`No document with ID ${groupId} found.`);
        return null;
      }
    } catch (error) {
      console.error("Error retrieving document:", error);
      throw error;
    }
  };

  React.useEffect(() => {
    getGroupInfo(user?.groupId);
  }, [user?.groupId]); // Re-run effect when groupId changes





  const [open, setOpen] = React.useState(false);

  const [failureOpen, setOpenFailure] = React.useState(false);

  const openSuccess = () => {
    setOpen(true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFailure(false);
  };
  const [newGroupId, setNewGroupId] = React.useState("");

  const [projectNumberFormat, setProjectNumberFormat] = React.useState(
    user.projectNumberFormat
  );

  const [invoiceNumberFormat, setInvoiceNumberFormat] = React.useState(
    user.invoiceNumberFormat
  );

  const [newUserPhone, setNewUserPhone] = React.useState("");
  const [newGroupAddress, setNewGroupAddress] = React.useState("");
  const [newGroupTelephone, setNewGroupTelephone] = React.useState("");
  const [newGroupFax, setNewGroupFax] = React.useState("");

  const onSubmitGroup = () => {
    checkIfGroupExists(newGroupId).then((exists) => {
      if (exists) {
        const thisProfile = doc(db, "profiles", user?.userId);
        setDoc(thisProfile, { groupId: newGroupId }, { merge: true })
          .then(() => {
            openSuccess();
          })
          .catch((error) => {
            openFailure();
          });
      } else {
        openFailure();
      }
    });
    setNewGroupId("");
  };

  const onSubmitPhone = () => {
    const thisProfile = doc(db, "profiles", user?.userId);
    setDoc(thisProfile, { phone: newUserPhone }, { merge: true })
      .then(() => {
        openSuccess();
      })
      .catch((error) => {
        openFailure();
      });
    setNewUserPhone("");
  };



  const onSubmitAddress = () => {
    const thisGroup = doc(db, "groups",user?.groupId);
    setDoc(thisGroup, { groupAddress: newGroupAddress}, { merge: true })
      .then(() => {
        openSuccess();
      })
      .catch((error) => {
        openFailure();
      });
      setGroupAddress(newGroupAddress)
      setNewGroupAddress("");
  };

  const onSubmitTelephone = () => {
    const thisGroup = doc(db, "groups",user?.groupId);
    setDoc(thisGroup, { groupTelephone:newGroupTelephone}, { merge: true })
      .then(() => {
        openSuccess();
      })
      .catch((error) => {
        openFailure();
      });
      setGroupTelephone(newGroupTelephone)
      setNewGroupTelephone("");
  };

  const onSubmitFax = () => {
    const thisGroup = doc(db, "groups",user?.groupId);
    setDoc(thisGroup, { groupFax: newGroupFax}, { merge: true })
      .then(() => {
        openSuccess();
      })
      .catch((error) => {
        openFailure();
      });
      setGroupFax(newGroupFax)
      setNewGroupFax("");
  };



  const onSubmitAutonumber = (value, field) => {
    const key = field;
    const thisProfile = doc(db, "profiles", user?.userId);
    setDoc(thisProfile, { [key]: value }, { merge: true }).catch(
      (error) => {
        openFailure();
      }
    );
  };

  const handleAutonumberToggle = (field) => {
    onSubmitAutonumber(!user[field], field);
  };

  const onSubmitProjectNumberFormat = () => {
    const thisProfile = doc(db, "profiles", user?.userId);
    setDoc(
      thisProfile,
      { projectNumberFormat: projectNumberFormat },
      { merge: true }
    )
      .then(() => {
        openSuccess();
      })
      .catch((error) => {
        openFailure();
      });
  };

  const onSubmitInvoiceNumberFormat = () => {
    const thisProfile = doc(db, "profiles", user?.userId);
    setDoc(
      thisProfile,
      { invoiceNumberFormat: invoiceNumberFormat },
      { merge: true }
    )
      .then(() => {
        openSuccess();
      })
      .catch((error) => {
        openFailure();
      });
  };


  // Fetch the profile data when the component mounts
  React.useEffect(() => {
    // const fetchProfile = async () => {
    //   if (user?.userId) {
    //     const profileRef = doc(db, "profiles", user?.userId);
    //     const profileSnap = await getDoc(profileRef);

    //     if (profileSnap.exists()) {
    //       const profileData = profileSnap.data();
    //       setLogoUrl(profileData.logoUrl || "");
    //     } else {
    //       console.log("No profile found");
    //     }
    //   }
    // };

    // fetchProfile();



 const fetchProfile = async () => {
      if (user?.userId) {
        const groupRef = doc(db, "groups", user?.groupId);
        const groupSnap = await getDoc(groupRef);

        if (groupSnap.exists()) {
          const groupData = groupSnap.data();
          setLogoUrl(groupData.groupLogo || "");
        } else {
          console.log("No profile found");
        }
      }
    };

    fetchProfile()


    setInvoiceNumberFormat(user.invoiceNumberFormat)
  }, [user?.userId]);

  // const onSubmitLogo = (file) => {
  //   if (!file) return;

  //   const storageRef = ref(storage, `logos/${user?.userId}`);
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       console.log(`Upload is ${progress}% done`);
  //     },
  //     (error) => {
  //       console.error("Upload failed:", error);
  //       openFailure();
  //     },
  //     () => {
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         setLogoUrl(downloadURL); // Update the local state with the new URL
  //         setNewLogoFile(null);
  //         const thisProfile = doc(db, "profiles", user?.userId);
  //         setDoc(thisProfile, { logoUrl: downloadURL }, { merge: true })
  //           .then(() => {
  //             openSuccess();
  //           })
  //           .catch((error) => {
  //             openFailure();
  //           })
  //       });
  //     }
  //   );
  // };

  const onSubmitLogo = (file) => {
    if (!file) return;
  
    const storageRef = ref(storage, `logos/${user?.groupId}`); // Store logo under group ID
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("Upload failed:", error);
        openFailure();
      },
      () => {
        // Get the download URL after upload
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setLogoUrl(downloadURL); // Update the local state with the new URL
          setNewLogoFile(null);
  
          // Update the Firestore document in the "groups" collection
          const groupDoc = doc(db, "groups", user?.groupId);
          setDoc(groupDoc, { groupLogo: downloadURL }, { merge: true }) // Update groupLogo field
            .then(() => {
              openSuccess(); // Notify success
            })
            .catch((error) => {
              console.error("Error updating Firestore:", error);
              openFailure(); // Notify failure
            });
        });
      }
    );
  };
  




// const onDeleteLogo = async () => {
//   Swal.fire({
//     title: "Are you sure?",
//     text: "You won't be able to revert this!",
//     icon: "warning",
//     showCancelButton: true,
//     confirmButtonColor: "#3085d6",
//     cancelButtonColor: "#d33",
//     confirmButtonText: "Yes, delete it!",
//     cancelButtonText: "Cancel",
//   }).then((result) => {
//     if (result.isConfirmed) {
//       // Proceed with deletion if confirmed
//       const storageRef = ref(storage, `logos/${user?.userId}`);
//       deleteObject(storageRef)
//         .then(async () => {
//           // Remove logoUrl from Firestore
//           const thisProfile = doc(db, "profiles", user?.userId);
//           await setDoc(thisProfile, { logoUrl: "" }, { merge: true });
//           setLogoUrl(""); // Clear local state
//           openSuccess();

//           Swal.fire(
//             "Deleted!",
//             "Your logo has been deleted.",
//             "success"
//           );
//         })
//         .catch((error) => {
//           console.error("Error deleting logo:", error);
//           openFailure();

//           Swal.fire(
//             "Error!",
//             "There was an issue deleting your logo.",
//             "error"
//           );
//         });
//     }
//   });
// };


const onDeleteLogo = async () => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "Cancel",
  }).then((result) => {
    if (result.isConfirmed) {
      // Proceed with deletion if confirmed
    
        const filePath = new URL(logoUrl).pathname.split("/o/")[1].split("?")[0];
        const decodedPath = decodeURIComponent(filePath);

        const storageRef = ref(storage, decodedPath);
      deleteObject(storageRef)
        .then(async () => {
          const groupDoc = doc(db, "groups", user?.groupId);
          await setDoc(groupDoc, { groupLogo: "" }, { merge: true });

          // Update local state or notify success
          setLogoUrl(""); // Clear local state
          openSuccess();

          Swal.fire(
            "Deleted!",
            "Your logo has been deleted from the group.",
            "success"
          );
        })
        .catch((error) => {
          console.error("Error deleting logo:", error);
          openFailure();

          Swal.fire(
            "Error!",
            "There was an issue deleting your logo.",
            "error"
          );
        });
    }
  });
};




  return (
    <>
      <Fade in={true}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
            <Typography align="left" variant="h4">
              MY PROFILE
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={8} style={{ position: "sticky",fontWeight:"700",marginBottom:"-20px",marginTop:"30px"}}>
            <Typography align="left" variant="h4" sx={{ fontWeight: "bold",color: "#111715", // CSS color name
            }}>
              Personal Information
          </Typography>
           </Grid>



          <Grid item xs={12} md={6} lg={6}>
            <ListItem>
              <Typography variant="h6">{`Name: ${user?.displayName}`}</Typography>
            </ListItem>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ListItem>
              <Typography variant="h6">{`Email: ${user?.email}`}</Typography>
            </ListItem>
          </Grid>
          {user?.phone && !newUserPhone ? (
            <Grid item xs={12} md={6} lg={6}>
              <ListItem>
                <Typography
                  variant="h6"
                  sx={{ width: "calc(100% - 74px)", display: "inline-flex" }}
                >
                     <Typography variant="h6">{`Telephone: ${user?.phone}`}</Typography>
                 
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    marginLeft: "10px",
                    display: "inline-flex",
                  }}
                  onClick={() => setNewUserPhone(user?.phone)}
                >
                  Edit
                </Button>
              </ListItem>
            </Grid>
          ) : (
            <Grid item xs={12} md={6} lg={6}>
              <ListItem>
                {/*                <Input
                  color={"secondary"}
                  value={newUserPhone}
                  onChange={(e) => setNewUserPhone(e.target.value)}
                /> */}
                <TextField
                  label="Phone number"
                  variant="outlined"
                  name="newUserPhone"
                  value={newUserPhone}
                  onChange={(e) => setNewUserPhone(e.target.value)}
                  sx={{ width: "calc(100% - 78px)" }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ marginLeft: "10px", marginTop: "10px" }}
                  onClick={onSubmitPhone}
                >
                  Save
                </Button>
              </ListItem>
            </Grid>
          )}

          {/* {isPro &&
          user?.groupId &&
          user?.groupId !== user.uid &&
          !newGroupId ? (
            <Grid item xs={12} md={6} lg={6}>
              <ListItem>
                <Typography
                  variant="h6"
                  sx={{ width: "calc(100% - 74px)", display: "inline-flex" }}
                >
                  {groupName ? groupName : user?.groupId || "not assigned"}
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    marginLeft: "10px",
                    display: "inline-flex",
                  }}
                  onClick={() => setNewGroupId(groupId)}
                >
                  Edit
                </Button>
              </ListItem>
            </Grid>
          ) : isPro ? (
            <Grid item xs={12} md={6} lg={6}>
              <ListItem>
               
                <TextField
                  label="Group ID"
                  variant="outlined"
                  name="groupId"
                  value={newGroupId}
                  onChange={(e) => setNewGroupId(e.target.value)}
                  sx={{ width: "calc(100% - 78px)" }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ marginLeft: "10px", marginTop: "10px" }}
                  onClick={onSubmitGroup}
                >
                  Save
                </Button>
              </ListItem>
            </Grid>
          ) : null} */}

                    <Grid item xs={12} md={6} lg={8} style={{ position: "sticky",fontWeight:"700",marginBottom:"-20px",marginTop:"30px"}}>
            <Typography align="left" variant="h4" sx={{ fontWeight: "bold",color: "#111715", // CSS color name
            }}>
              Preferences
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ListItem>
              <Switch
                checked={user.autonumber}
                onChange={() => handleAutonumberToggle("autonumber")}
              />
              Auto-number Jobs
              {user.autonumber && (
                <>
                  <TextField
                    label="Project number format"
                    variant="outlined"
                    name="groupId"
                    value={projectNumberFormat}
                    onChange={(e) => setProjectNumberFormat(e.target.value)}
                    sx={{ width: "calc(100% - 78px)" }}
                  />{" "}
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px", marginTop: "10px" }}
                    onClick={onSubmitProjectNumberFormat}
                  >
                    Save
                  </Button>
                </>
              )}
            </ListItem>

            <ListItem>
              <Switch
                checked={user.invoiceAutonumber}
                onChange={() => handleAutonumberToggle("invoiceAutonumber")}
              />
              Auto-number Invoices
              {user.invoiceAutonumber && (
                <>
                  <TextField
                    label="Invoice number format"
                    variant="outlined"
                    name="groupId"
                    value={invoiceNumberFormat}
                    onChange={(e) => setInvoiceNumberFormat(e.target.value)}
                    sx={{ width: "calc(100% - 78px)" }}
                  />{" "}
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px", marginTop: "10px" }}
                    onClick={onSubmitInvoiceNumberFormat}
                  >
                    Save
                  </Button>
                </>
              )}
            </ListItem>
          </Grid>









        {user.subscription!=='trial' && <>
                 <Grid item xs={12} md={6} lg={8} style={{ position: "sticky",fontWeight:"700",marginBottom:"-20px",marginTop:"30px"}}>
         <Typography align="left" variant="h4" sx={{ fontWeight: "bold",color: "#111715", 
            }}>
              Company Information
            </Typography>
          </Grid>


          <Grid item xs={12} md={6} lg={6}>
         <ListItem>
          <Typography variant="h6">{`${groupName}`}</Typography>
       </ListItem>
     </Grid>



               {groupAddress && !newGroupAddress ? (
  <Grid item xs={12} md={6} lg={6}>
    <ListItem>
      <Typography
        variant="h6"
         sx={{ width: "calc(100% - 74px)", display: "inline-flex" }}
      >
         Address: {groupAddress}
       </Typography>
      <Button
        color="primary"
        variant="contained"
        sx={{
          marginLeft: "10px",
          display: "inline-flex",
        }}
        onClick={() => setNewGroupAddress(groupAddress)}
      >
        Edit
      </Button>
    </ListItem>
  </Grid>
) : (
  <Grid item xs={12} md={6} lg={6}>
    <ListItem>
      <TextField
        label="Address"
        variant="outlined"
        name="newGroupAddress"
        value={newGroupAddress}
        onChange={(e) => setNewGroupAddress(e.target.value)}
        sx={{ width: "calc(100% - 78px)" }}
        error={newGroupAddress === ""}
        helperText={newGroupAddress === "" ? "Please enter an address" : ""}
      />
      <Button
        color="primary"
        variant="contained"
        sx={{ marginLeft: "10px", marginTop: "10px" }}
        onClick={onSubmitAddress}
        disabled={newGroupAddress === ""}
      >
        Save
      </Button>
    </ListItem>
  </Grid>
)}




{groupTelephone && !newGroupTelephone ? (
  <Grid item xs={12} md={6} lg={6}>
    <ListItem>
      <Typography
        variant="h6"
         sx={{ width: "calc(100% - 74px)", display: "inline-flex" }}
      >
         Telephone: {groupTelephone}
       </Typography>
      <Button
        color="primary"
        variant="contained"
        sx={{
          marginLeft: "10px",
          display: "inline-flex",
        }}
        onClick={() => setNewGroupTelephone(groupTelephone)}
      >
        Edit
      </Button>
    </ListItem>
  </Grid>
) : (
  <Grid item xs={12} md={6} lg={6}>
    <ListItem>
      <TextField
        label="Telephone"
        variant="outlined"
        name="newGroupTelephone"
        value={newGroupTelephone}
        onChange={(e) => setNewGroupTelephone(e.target.value)}
        sx={{ width: "calc(100% - 78px)" }}
        //  error={newGroupTelephone === ""}
        // helperText={newGroupTelephone === "" ? "Please enter a telephone" : ""}
      />
      <Button
        color="primary"
        variant="contained"
        sx={{ marginLeft: "10px", marginTop: "10px" }}
        onClick={onSubmitTelephone}
        // disabled={newGroupTelephone === ""}
      >
        Save
      </Button>
    </ListItem>
  </Grid>
)}





{groupFax && !newGroupFax ? (
  <Grid item xs={12} md={6} lg={6}>
    <ListItem>
      <Typography
        variant="h6"
         sx={{ width: "calc(100% - 74px)", display: "inline-flex" }}
      >
         Fax: {groupFax}
       </Typography>
      <Button
        color="primary"
        variant="contained"
        sx={{
          marginLeft: "10px",
          display: "inline-flex",
        }}
        onClick={() => setNewGroupFax(groupFax)}
      >
        Edit
      </Button>
    </ListItem>
  </Grid>
) : (
  <Grid item xs={12} md={6} lg={6}>
    <ListItem>
      <TextField
        label="Fax"
        variant="outlined"
        name="newGroupFax"
        value={newGroupFax}
        onChange={(e) => setNewGroupFax(e.target.value)}
        sx={{ width: "calc(100% - 78px)" }}
        //  error={newGroupTelephone === ""}
        // helperText={newGroupTelephone === "" ? "Please enter a telephone" : ""}
      />
      <Button
        color="primary"
        variant="contained"
        sx={{ marginLeft: "10px", marginTop: "10px" }}
        onClick={onSubmitFax}
        // disabled={newGroupTelephone === ""}
      >
        Save
      </Button>
    </ListItem>
  </Grid>
)}



<Grid item xs={12} md={6} lg={6}>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
               <Typography align="left" variant="h6" sx={{  
            }}>
              Company Logo
            </Typography>
              {logoUrl && !newLogoFile ? (
                <>
           
                  <img
                    src={logoUrl}
                    alt="User Logo"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                          <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    onClick={() => setNewLogoFile(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    onClick={onDeleteLogo}
                  >
                    Delete
                  </Button>
                  </div>
          
                </>
              ) : (
                <>
                  <TextField
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    onChange={(e) => setNewLogoFile(e.target.files[0])}
                    sx={{ width: "calc(100% - 78px)" }}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px", marginTop: "10px" }}
                    onClick={() => onSubmitLogo(newLogoFile)}
                  >
                    Save
                  </Button>
                </>
              )}
            </ListItem>



          </Grid>





          </>
           }

        </Grid>

      </Fade>

      {user.subscription!=='trial' && <>
      <DocumentsTypes />
        </>
        }
      
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Profile successfully updated!
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureOpen}
        autoHideDuration={2000}
        onClose={handleFailureClose}
      >
        <Alert
          onClose={handleFailureClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Something went wrong!
        </Alert>
      </Snackbar>
    




    </>
  );
};